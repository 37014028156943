<template>
  <div id="core-view">
    <div
      v-if="showBar"
      style="background-color: #01baef; width: 100%;">
      <router-link
        to="/product-list"
        class="px-3 py-2 d-flex white--text font-weight-bold">
        <span>{{ $t('thirdParty.backToStore') }}</span>
      </router-link>
    </div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'WAP Content'
    }
  },
  data: () => ({
    showBar: true
  }),
  watch: {
    $route (to, from) {
      this.checkRouteChanged(to)
    }
  },
  created () {
    this.checkRouteChanged(this.$route)
  },
  methods: {
    checkRouteChanged: function (toRoute) {
      this.showBar = (this.$route.path !== '/product-list' || this.$route.path !== '/wap-home')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
