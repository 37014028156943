<template>
  <div style="background-color: #fff;">
    <wapHeader />
    <wapView style="margin-top: 70px;" />
  </div>
</template>
<script>
import WapView from './View.vue'
import WapHeader from './Header.vue'
export default {
  components: {
    WapView,
    WapHeader
  }
}
</script>

<style>

</style>
